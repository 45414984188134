<template>
    <div>
        <div class="ft20 cl-black cl-main ftw600">服务人员统计</div>

        <div class="mt20">
            <div class="form-search-box ">
                <a-form layout="inline">
                    <a-form-item label="分类名称">
                        <a-input v-model="search.name" placeholder="请输入分类名称"></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="searchAct" type="primary">查询</a-button>
                        <a-button @click="cancelAct" class="ml10">取消</a-button>
                        <a-button type="primary" class="ml10" icon="export" @click="exportAct()">导出统计</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>

        <div class="mt20">
            <div class="bg-w">
                <div class="pd30">
                    <div class="wxb-table-gray">
                        <a-table :columns="columns" :pagination="pagination" :data-source="datas" @change="handleTableChange" :loading="loading">

                        </a-table>
                    </div>
                </div>
            </div>
        </div>



    </div>

</template>

<script>
    import {listMixin} from '../../common/mixin/list.js';
    export default {
        mixins: [listMixin],
        data() {
            return {
                loading: false,
                editloading:false,
                miniqrcodeImg:false,
                jionDataLoing:false,
                miniqrcode:'',
                confirmLoading:false,
                pagination: {
                    current: 1,
                    pageSize: 10, //每页中显示10条数据
                    total: 0,
                },
                search: {
                    name: '',
                },
                level:[],
                columns: [
                    {title: '编号',dataIndex: 'project_type_id',align: 'center',width:100},
                    {title: '分类名称',dataIndex: 'name',align: 'left',width:200},
                    {title: '项目数',dataIndex: 'project_count',align: 'left',width:200},
                    {title: '工单数',dataIndex: 'appointment_count',ellipsis: true},

                ],
                datas: [],
                member:{},
            }
        },
        methods: {
            getLists(){
                if(this.loading==true) return;
                this.loading=true;
                this.$http.api('platform/statisticsProject',{
                    name:this.search.name,
                    limit:this.pagination.pageSize,
                    page:this.pagination.current,
                }).then(res=>{
                    this.pagination.total=res.total;
                    this.pagination.showTotal=(total)=>"共"+res.total+"条";
                    this.datas=res.list;

                    this.loading=false;
                }).catch(res=>{
                    this.loading=false;
                })
            },

            exportAct() {
                let href = process.env.VUE_APP_BASE_API + "housekeeping/platform/exportProjectType?client_env="+localStorage.getItem('client-env-platform')+"&auth_token=" + encodeURIComponent(localStorage.getItem('housekeeping-token'));
                if (this.search.name != '') {
                    href = href + '&name=' + encodeURIComponent(this.search.name);
                }
                window.location.href = href;
            },
            handleTableChange(pagination, filters, sorter) {
                this.pagination.current = pagination.current;
                this.getLists();
            },
        }
    }
</script>

<style>
    .index-face {
        width: 44px;
        height: 44px;
        border-radius: 44px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
        border: 2px solid #FFFFFF;
    }

    .member-index-action {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background: #FFFFFF;
        border: 1px solid #EBEDF5;
        text-align: center;
        line-height: 32px;
    }
</style>
